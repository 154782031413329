<template>
	<pop-up-window
		:show="showResendDocument"
		:showClose=false
		:width60=true
		:bottomDiv=true
		:positionAbsolute=true
		:top10=true
		fixed
	>
		<template #title>
			<h1 class="text-2xl">{{ $t('documentDetail.resendDocument.title') }}</h1>
		</template>
		<template #content>
			<div class="user-info divider">
				<div class="button-container-outer">

					<div class="button-container-inner">
						<div v-for="(isActive, type) in resendTypes" :key="type">
							<div
								@mouseenter=displayTooltip(type,true)
								@mouseleave=displayTooltip(type,false)
							>
								<button
									class="btn custom-button text-popup"
									:class="{'inactive' : !isActive}"
									v-if="displayButton(type)"
									@click="makeActive(type)"
									:disabled=displayDisable(type)
								>
									{{ buttonTitle(type) }}
								</button>
							</div>
						</div>
					</div>
					<div class="tooltip-container">
						<div
							class="tooltip edi-tooltip"
							:class="{'display-tooltip' : displayTooltipEdi}"
						>
							{{ ediToolTip }}
						</div>
					</div>
				</div>
				<div class="tooltip-container-attachMail">
					<div
						class="tooltip attachMail-tooltip"
						:class="{'display-tooltip' : displayTooltipAttachMail}"
					>
						{{ ediToolTip }}
					</div>
				</div>
				<div>
					<resend-document-mail
						v-if="displayMail"
						:docId=docId
						:legalEntityId=legalEntityId
						:docsData=docsData
						:mailType=mailType
						:email="email"
						@resend="unselectedDocuments"
						@cancel="closePopUp"
					>
					</resend-document-mail>

					<resend-document-print
						v-if="displayPrint && !this.$store.state.resendBatch"
						:docId=docId
						:legalEntityId=legalEntityId
						:docsData=docsData
						@close-pop-up="closePopUp"
					>
					</resend-document-print>

					<resend-document-edi
						v-if="displayEdi"
						:docId=docId
						:legalEntityId=legalEntityId
						:docsData=docsData
						@close-pop-up="closePopUp"
					>
					</resend-document-edi>

				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow.vue"
import ResendDocumentEdi from "../resend/ResendDocumentEdi.vue"
import ResendDocumentPrint from "../resend/ResendDocumentPrint.vue"
import ResendDocumentMail from "../resend/ResendDocumentMail.vue"
import i18n from "../../../i18n"

export default {
	beforeMount() {
		if (this.docId !== undefined && this.$route.name !== 'public') this.$store.dispatch('documents/readFileSize', {documentId: this.docId})
	},
	name: "ResendDocument",
	components: {ResendDocumentMail, ResendDocumentPrint, ResendDocumentEdi, PopUpWindow},
	emits: ['unselectDocuments', 'closePopUp'],
	props: {
		docId: {
			type: Array
		},
		legalEntityId: {
			type: String
		},
		docsData: {
			type: Array
		},
		showResendDocument: Boolean,
		email: {
			type: String,
			required: false
		},
	},
	watch: {
		docsData() {
			return this.docsData
		}
	},
	mounted() {
		this.ediResendCheck()
		this.setActiveTab()
	},
	data() {
		return {
			currentDocId: this.docId,
			currentLegalEntityId: this.legalEntityId,
			activeType: 'attachMail',
			disabled: true,
			ediCheck: {
				noVAT: false,
			},
			resendTypes: {
				attachMail: true,
				linkMail: false,
				print: false,
				edi: false,
			},
			i18n: i18n,
			displayTooltipEdi: false,
			displayTooltipAttachMail: false,
		}
	},
	computed: {
		ediToolTip() {
			if (this.$store.state.documentDetailData?.fileStructure?.ublFileName === undefined) {
				return i18n.t('buttons.ediDisabledTooltip.noValidUbl')
			} else if (this.$store.state.documentFileSizes.pdfSize >= 10) {
				return i18n.t('buttons.disableAtttachMailTooltip')
			}
			return 'No Item to display'
		},

		mailType() {
			let mailType = "ATTACHMENT"
			if (this.activeType === 'linkMail') mailType = "LINK"
			return mailType
		},
		displayEdi() {
			return this.activeType === 'edi'
		},
		displayMail() {
			const pdfSize = this.$store.state.documentFileSizes.pdfSize
			if (this.activeType === "print" || this.activeType === "edi") {
				return false
			}

			if ((this.activeType === "attachMail" && pdfSize >= 10) || this.activeType === "linkMail") {
				if (this.activeType === "linkMail") {
					if (this.$store.state.supplierGroup.data.channelsConfig.linkEmail) {
						this.makeActive("linkMail")
						return true
					} else {
						this.makeActive("attachMail")
						return false
					}
				}
				if (pdfSize >= 10) {
					this.makeActive("linkMail")
					return true
				}
				this.makeActive("attachMail")
				return true
			}
			return true
		},
		displayPrint() {
			return !!(this.activeType === "print"
				&& this.$store.state.supplierGroup.data.channelsConfig?.print?.printJobs
				&& this.$store.state.supplierGroup.data.channelsConfig.print.printProvider === "SPEOS")
		}
	},

	methods: {
		unselectedDocuments() {
			this.$emit("unselectDocuments")
			this.$emit('closePopUp')
		},
		closePopUp(){
			this.$emit('closePopUp')
		},
		setActiveTab() {
			if (this.$store.state.askPopUpAction === "checkOnPeppol") {
				this.resendTypes = {
					attachMail: false,
					linkMail: false,
					print: false,
					edi: true,
				}
				this.activeType = "edi";
			}
		},
		ediResendCheck() {
			if (this.$store.state.resendBatch) {
				let noVats = []
				this.docsData.forEach(doc => {
					noVats.push(doc.noVat)
				})

				let uniqueNoVat = [...new Set(noVats)]
				this.ediCheck.noVAT = !(uniqueNoVat.length === 1 && uniqueNoVat[0] === false)
			} else {
				if (this.$store.state.docType === 'BILLING') {
					if (this.$store.state.documentDetailData.billingData?.billingTo?.registration?.registrationNumber) {
						this.ediCheck.noVAT = false
					}
				}
			}
		},
		displayTooltip(type, display) {
			if (type === 'edi' && this.displayDisable(type)) this.displayTooltipEdi = display
			else if (type === "attachMail" && this.displayDisable(type)) this.displayTooltipAttachMail = display
		},
		displayDisable(type) {
			if (type === "edi" && this.$store.state.documentDetailData?.fileStructure?.ublFileName === undefined) {
				return true
			} else if (type === "attachMail" && this.$store.state.documentFileSizes.pdfSize >= 10) {
				return true
			}
			return false
		},
		displayButton(type) {
			if (type === 'edi') {
				if (this.$store.state.docType !== "BILLING") return false
				if (this.$store.state.resendBatch) return false
				if (this.$store.state.supplierGroup.data.channelsConfig?.edi) return true
			}

			if (type === 'attachMail' && this.$store.state.supplierGroup.data.channelsConfig) {
				return this.$store.state.supplierGroup.data.channelsConfig.attachEmail
			}
			if (type === 'linkMail' && this.$store.state.supplierGroup.data.channelsConfig) {
				return this.$store.state.supplierGroup.data.channelsConfig.linkEmail
			}
			if (type === 'print') {
				return !!(this.$store.state.supplierGroup.data.channelsConfig?.print?.printJobs
					&& this.$store.state.supplierGroup.data.channelsConfig.print.printProvider === "SPEOS"
					&& !this.$store.state.resendBatch)
			}
			return true
		},
		buttonTitle(type) {
			if (type === "linkMail") return i18n.t('buttons.resendLink')
			if (type === "print") return i18n.t('buttons.resendPrint')
			if (type === "edi") return i18n.t('buttons.resendEdi')
			return i18n.t('buttons.resendAttach')
		},

		makeActive(resendType) {
			for (let type in this.resendTypes) {
				this.resendTypes[type] = false;
			}
			this.resendTypes[resendType] = true;
			this.activeType = resendType
		}
	}
}
</script>

<style scoped>
.button-container-outer {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	justify-content: end;
}

.button-container-inner {
	display: grid;
	grid-template-columns: repeat(10, auto);
	grid-template-rows: 100%;
	grid-auto-flow: row;
	justify-self: end;
}

@media only screen and (max-width: 680px) {
	.button-container-inner {
		grid-template-columns: repeat(2, auto);
		grid-template-rows: auto auto;
		grid-auto-flow: column;
		grid-row-gap: 4px;

	}
}

.button-container-inner button {
	margin-left: 10px;
}

.inactive {
	background-color: var(--theme-primary-100);
	border-color: rgba(85, 85, 85, var(--theme-primary-100));
}

.tooltip-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 24px;
	grid-auto-flow: row;
	justify-items: end;
}

.tooltip-container-attachMail {
	display: flex;
	justify-content: center;
}

.edi-tooltip {
	top: -5px;
	padding: 0 5px;
}

.attachMail-tooltip {
	top: -25px;
	justify-content: center;

}

.display-tooltip {
	display: block;
}

button:disabled:hover {
	display: block;
}

</style>
