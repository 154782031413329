<template>
	<div>
		<error-notification/>
		<!-- BEGIN: HTML Table Data -->
		<div class="intro-y box p-5 mt-5" id="tabulatorOuter">
			<div class="overflow-x-auto scrollbar-hidden">
				<div
					id="tabulator"
					ref="tableRef"
					class="mt-5 table-report table-report--tabulator"
				/>
			</div>
		</div>
		<!-- END: HTML Table Data -->
	</div>
</template>

<script>
import moment from 'moment'
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue'
import feather from 'feather-icons'
import {TabulatorFull as Tabulator} from 'tabulator-tables'
import {useStore} from 'vuex'
import cash from "cash-dom"
import ErrorNotification from "../../UI/ErrorNotification.vue"
import i18n from "../../../i18n"

export default defineComponent({
	components: {ErrorNotification},
	emits: ['update:modelValue'],
	props: {
		token: String,
		supplierGroupId: String,
		general: Boolean,
		salary: Boolean,
		billing: Boolean,
		requestUrl: String,
		modelValue: String,
		selectedLE: String,
		selectedReceivers: Array,
	},
	setup(props,) {
		const en = require('../../../locales/en.json')
		const nl = require('../../../locales/nl.json')
		const fr = require('../../../locales/fr.json')
		const de = require('../../../locales/de.json')
		const es = require('../../../locales/es.json')
		const sk = require('../../../locales/sk.json')
		const store = useStore()

		const selectedDocuments = ref([])
		const requestUrl = ref(props.requestUrl)
		const token = ref(props.token)
		const supplierGroupId = ref(props.supplierGroupId)
		const selectedLE = ref(props.selectedLE)
		const selectedReceivers = ref(props.selectedReceivers)
		const totalDocuments = ref('')
		const searchTableValue = store.state.tableSearchValue
		const tableRef = ref()
		const tabulator = ref()
		const tableIsLoading = ref(false)
		const filter = reactive({
			value: searchTableValue
		})
		const pageSize = ref(10)
		let displayButton = computed(() => {
			return !(store.state.docType === "SALARY" || store.state.docType === "GENERAL")
		})

		watch(() => props.token, (newVal) => {
			initTabulator()
			token.value = newVal
		})
		watch(() => props.selectedReceivers, (newVal) => {
			selectedReceivers.value = newVal
			initTabulator()
		})
		watch(() => props.selectedLE, (newVal) => {
			selectedLE.value = newVal
			initTabulator()
		})
		watch(() => store.getters.getLocale, function () {
			tabulator.value.setLocale(store.getters.getLocale)
		})

		const initTabulator = async () => {
			await store.dispatch("updateToken")
			tabulator.value = new Tabulator(tableRef.value, {
				ajaxURL: requestUrl.value,
				ajaxConfig: {
					method: "POST",
					headers: {
						'Authorization': 'bearer ' + store.state.token,
						'Accept': 'application/json',
					},
				},
				ajaxParams: {
					pageNo: 1,
					pageSize: pageSize.value,
					supplierGroupId: supplierGroupId.value,
					legalEntityId: selectedLE.value,
					receiverIds: selectedReceivers.value,
				},
				ajaxResponse: function (url, params, response) {
					let data = []
					const res = response.data.users
					for (let item of res) {
						let tempItem = {
							supplierGroupId: item.supplierGroupId,
							id: item.id,
							userData: item,
							email: item.email,
							firstName: item.firstName ? item.firstName : "-",
							language: item.language,
							lastName: item.lastName ? item.lastName : "-",
							legalEntities: item.legalEntities,
							role: item.group,
							lastLogin: item.lastLogin ? moment(item.lastLogin).format('DD/MM/YYYY') : '-',
							createdOn: moment(item.createdOn).format('DD/MM/YYYY')
						}
						data.push(tempItem)
					}
					let last_page = Math.ceil(response.data.numResults / pageSize.value)
					return {
						last_page: last_page,
						data: data,
					}
				},
				dataSendParams: {
					page: "pageNo",
					size: "page_size",
					filter: "quick",
				},
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
				ajaxContentType: "json",
				ajaxFiltering: true,
				printAsHtml: true,
				printStyled: true,
				pagination: true,
				paginationMode: 'remote',
				paginationSize: pageSize.value,
				layout: 'fitColumns',
				responsiveLayout: 'collapse',
				placeholder: i18n.t('placeholders.noMatchingFound'),
				locale: true,
				langs: {
					"en": en,
					"nl": nl,
					"de": de,
					"fr": fr,
					"sk": sk,
					"es": es,
				},
				columns: [
					{
						title: 'E-MAIL',
						field: 'email',
						minWidth: 250,
						headerSort: false,
						formatter(cell) {
							let email = cell.getData().email ? cell.getData().email : cell.getData().userData.username;
							return `<div  class="font-medium whitespace-nowrap cell-font cell-style">
                          				${email}
                    				</div>`
						}
					},
					{
						title: 'Roles',
						field: 'role',
						minWidth: 120,
						headerSort: false,
						cssClass: "text-centered",
						hozAlign: "center",
						formatter(cell) {
							return `<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">
                             			${cell.getData().role}
                        			</div>`
						}
					},
					{
						title: 'LANGUAGE',
						field: 'language',
						minWidth: 80,
						headerSort: false,
						cssClass: "text-centered",
						hozAlign: "center",
						formatter(cell) {
							let date = (cell.getData().language)
							const a = cash(
								`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">
                          					<div>${date}</div>
                    					 </div>`
							)
							return a[0]
						}
					},
					{
						title: 'CREATED AT',
						field: 'createdAt',
						minWidth: 80,
						headerSort: false,
						cssClass: "text-centered",
						hozAlign: "center",
						formatter(cell) {
							let date = (cell.getData().createdOn)
							const a = cash(
								`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">
                          					<div>${date}</div>
                    					 </div>`)
							return a[0]
						}
					},
					{
						title: 'LAST LOGIN',
						field: 'lastLogin',
						minWidth: 80,
						headerSort: false,
						cssClass: "text-centered",
						hozAlign: "center",
						formatter(cell) {
							let date = (cell.getData().lastLogin);
							const a = cash(
								`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">
                          					<div>${date}</div>
                    					</div>`)
							return a[0]
						}
					}
				]
			})
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
				tableIsLoading.value = false
			})
			tabulator.value.on('dataLoading', () => {
				tableIsLoading.value = true
			})
		}
		watch(tableIsLoading, () => {
			if (tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
		const setLocale = () => {
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
			})
		}
		const reInitOnResizeWindow = () => {
			window.addEventListener('resize', () => {
				tabulator.value.redraw()
				feather.replace({
					'stroke-width': 1.5
				})
			})
		}
		const onFilter = () => {
			selectedDocuments.value = []
			document.getElementById('selectAllDocuments').checked = false //to clean selectAll checkBox
			store.commit('tableSearchValue', filter.value) //to save search value on keyUp
			tabulator.value.setFilter(filter.value)
			tabulator.value.redraw()
		}
		const onResetFilter = () => {
			filter.value = ''
			onFilter()
			tabulator.value.redraw()
		}
		const getUsers = () => {
			initTabulator()
		}

		onMounted(() => {
			initTabulator()
			reInitOnResizeWindow()
			setLocale()
		})

		return {
			displayButton,
			tableRef,
			filter,
			onFilter,
			onResetFilter,
			selectedDocuments,
			totalDocuments,
			tabulator,
			getUsers,
		}
	}
})
</script>

<style scoped>
.leg-entity-container img {
	padding-right: var(--generalPaddings);
}

.leg-entity-container .table-report img {
	border: none;
	box-shadow: none !important;
}

#selectAllDocuments {
	justify-self: center;
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i {
	display: none;
}

.download-outer button {
	margin-left: 5px;
}

.download-buttons .btn span {
	grid-column: 1/2;
	grid-row: 1/2;
}
</style>