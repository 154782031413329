<template>
	<div>
		<pop-up-window
			v-if="!showConfirmDialog"
			:show="this.$store.state.receiverManagement.displayEditReceiver && displayComponent"
			:showClose=false
			@close="closeEditReceiver"
			class="doc-statuses-popup"
			:positionFixed=true
			:top10=true
			:stickToScrollPosition=true
			fixed
		>
			<template #title>
				<h1 class="" v-if="!this.$store.state.receiverManagement.viewMode">
					{{ $t('management.editReceiverChoice.title') }}
				</h1>
				<h1 class="" v-if="this.$store.state.receiverManagement.viewMode">
					{{ $t('management.viewReceiverChoice.title') }}
				</h1>
				<div class="text-center">
					<h1>{{ receiverData.receiverName }}, id: {{ receiverData.receiverId }}</h1>
				</div>
			</template>
			<template #content>
				<div v-if="showReleaseToggle" class="one-item">
					<h1>Hold release:</h1>
					<toggle-slide
						@update-value="updateHoldRelease"
						class="w-min"
						:bool="formData.holdRelease"
						:disabled="this.$store.state.receiverManagement.viewMode"
					/>
				</div>
				<div v-if="showOverrideChannel" class="one-item">
					<h1>{{ $t('management.selectChannel') }}</h1>
					<v-select
						:disabled="this.$store.state.receiverManagement.viewMode"
						v-model="selectedChannels"
						:options='channelOptions'
						multiple=true
						@option:selected=setChannelOptions()
						@option:deselected=deselectOptions()
					>
						<template #no-options="{search}">
							{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
						</template>
					</v-select>
				</div>
				<div class="ediInfo" v-if="displayEdiInfo">
					<div>
						<div class="one-item">
							<h1>{{ $t('management.ediInfo.companyIdentifierNumberType') }}</h1>
							<v-select
								:clearable=false
								:disabled="this.$store.state.receiverManagement.viewMode"
								v-model="companyIdentifierNumberType"
								:options='identifierNumberType'
								@click='setErrors("companyIdentifierNumberType", false)'
							>
								<template #no-options="{search}">
									{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
								</template>
							</v-select>
						</div>
						<p v-if="errors.companyIdentifierNumberType" class="error">
							{{ $t('errors.companyIdentifierNumberType') }}
						</p>
					</div>
					<div>
						<div class="one-item">
							<h1>{{ $t('management.ediInfo.companyIdentifierNumber') }}</h1>
							<input
								:disabled="this.$store.state.receiverManagement.viewMode"
								type='text'
								v-model="companyIdentifierNumber"
								@click='setErrors("companyIdentifierNumber", false); setErrors("companyIdentifierNumberWrongFormat", false)'
								class="form-control text-popup"
							>
						</div>
						<p v-if="errors.companyIdentifierNumber" class="error">
							{{ $t('errors.companyIdentifierNumber') }}
						</p>
						<p v-if="errors.companyIdentifierNumberWrongFormat" class="error">
							{{ $t('errors.companyIdentifierNumberGeneral') }}
						</p>
					</div>
				</div>
				<div v-if="displayReceiverEmails">
					<div>
						<div class="one-item">
							<h1 class="mt-4">{{ $t('management.senderEmail') }}</h1>
							<input
								:disabled="this.$store.state.receiverManagement.viewMode"
								type='text'
								v-model="senderEmail"
								@click='setErrors("senderEmail", false)'
								:placeholder="$t('placeholders.email')"
								class="form-control text-popup">
						</div>
						<p v-if="errors.senderEmail" class="error">{{ $t('errors.correctEmail') }}</p>
					</div>
					<div>
						<email-language-form
							:input-existing-contacts="existingContacts"
							:error-given-input="errors.givenMailInput"
							:languages="languages"
							@remove-contact="removeContact"
							:show-in-put-mail="!this.$store.state.receiverManagement.viewMode"
							ref="newEmails"
						/>
						<p v-if="errors.atLeastOneEmail" class="error">{{ $t('errors.atLeastOneEmail') }}</p>
					</div>
				</div>
				<div class="grid grid-rows-1 justify-items-center my-6">
					<div>
						<button class="btn custom-button shadow-md mr-2 showStatuses text-popup"
								@click="closeEditReceiver">
							{{ $t('buttons.cancel') }}
						</button>
						<button
							class="btn custom-button shadow-md mr-2 showStatuses text-popup"
							v-if="!this.$store.state.receiverManagement.viewMode"
							@click="updateReceiver">
							{{ $t('buttons.save') }}
						</button>
					</div>
				</div>
			</template>
		</pop-up-window>
		<div>
			<confirmation-with-props
				:description="descriptionConfirmDialog"
				:show-dialog="showConfirmDialog"
				:stick-to-scroll-position="true"
				@cancel-action="showConfirmDialog = false"
				@approve-action="confirmAddReceiver"
			/>
		</div>
	</div>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow"
import i18n from '../../../i18n'
import ToggleSlide from "../../UI/ToggleSlider.vue"
import EmailLanguageForm from "../../UI/EmailLanguageForm.vue"
import ConfirmationWithProps from "../../UI/ConfirmationWithProps.vue"

export default {
	name: "EditReceiver",
	components: {ConfirmationWithProps, EmailLanguageForm, ToggleSlide, PopUpWindow},
	props: {
		channels: Array,
		ediChannelsAvailable: Array,
		companyIdentifierNumberTypes: Array
	},
	mounted() {
		this.errors.givenMailInput.push(false)
	},
	data() {
		return {
			re: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),

			receiverData: {},
			displayComponent: false,
			selectedChannels: [],
			displayReceiverEmails: false,
			companyIdentifierNumberType: '',
			companyIdentifierNumber: '',
			newReceiverEmails: [],
			i18n: i18n,
			ediChannels: this.ediChannelsAvailable,
			channelOptions: JSON.parse(JSON.stringify(this.channels)),
			channelOptionsAvailable: this.channels,
			identifierNumberType: [
				{label: 'KBO', value: "KBO"},
				{label: 'KVK', value: "KVK"},
				{label: 'GLN', value: "GLN"},
			],
			errors: {
				companyIdentifierNumber: false,
				companyIdentifierNumberWrongFormat: false,
				companyIdentifierNumberType: false,
				atLeastOneEmail: false,
				senderEmail: false,
				givenMailInput: []
			},
			formData: {
				supplierGroupId: '',
				receiverId: '',
				legalEntityId: '',
				holdRelease: false,
				channelChoice: [],
				adiInfo: {}
			},
			showReleaseToggle: this.$store.state.supplierGroup.data.holdRelease,
			showOverrideChannel: this.$store.state.supplierGroup.data.channelsConfig.docImage.channelChoice?.modifyChannel ? this.$store.state.supplierGroup.data.channelsConfig.docImage.channelChoice.modifyChannel : false,
			showConfirmDialog: false,
			confirm: {
				alreadyConfirmed: false,
			},
			identifierNumberTypeMatch: this.companyIdentifierNumberTypes,
			senderEmail: '',
			removedOldContact: false
		}
	},
	computed: {
		languages() {
			let languages = this.$store.state.supplierGroup.data.languages
			let legalEntities = this.$store.state.legalEntitiesData.data.legalEntities
			if (this.formData.legalEntityId !== undefined && legalEntities[this.formData.legalEntityId]?.templates?.mail?.emailLanguages && legalEntities[this.formData.legalEntityId]?.templates?.mail?.emailLanguages.length > 0) {
				languages = legalEntities[this.formData.legalEntityId]?.templates?.mail?.emailLanguages && legalEntities[this.formData.legalEntityId]?.templates?.mail?.emailLanguages
			} else if (this.$store.state.supplierGroup.data.templates?.mail?.emailLanguages && this.$store.state.supplierGroup.data.templates?.mail?.emailLanguages.length > 0) {
				languages = this.$store.state.supplierGroup.data.templates?.mail?.emailLanguages
			}
			languages.sort()
			return languages
		},
		descriptionConfirmDialog() {
			if (this.showReleaseToggle && this.showOverrideChannel === false && this.formData.holdRelease === false) {
				return i18n.t('confirmation.description.editClient.confirmHoldReleaseEmpty')
			} else if (this.showReleaseToggle === false && this.showOverrideChannel && this.formData.channelChoice <= 0) {
				return i18n.t('confirmation.description.editClient.confirmChannelChoseEmpty')
			} else {
				if (this.formData.holdRelease === false && this.selectedChannels.length <= 0) {
					return i18n.t('confirmation.description.editClient.confirmHoldReleaseAndChannelChoseEmpty')
				}
			}
			return ''
		},
		existingContacts() {
			let contacts = []

			for (const language in this.formData.emails) {
				this.formData.emails[language].forEach(email => {
					contacts.push({
						email: email,
						language: language
					})
				})
			}
			return contacts
		},
		selectedLe() {
			let legalEntities = this.$store.state.legalEntitiesData.data.legalEntities
			let selectedLe

			for (const le in legalEntities) {
				if (le === this.formData.legalEntityId) {
					selectedLe = le
				}
			}
			return selectedLe
		},
		displayEdiInfo() {
			let isEdi = false
			this.selectedChannels.forEach(channel => {
				if (this.ediChannels.includes(channel.value)) {
					if (channel.value === 'Mercurius' || channel.value === 'OpenPeppol' || channel.value === 'Peppol'
						|| channel.value === 'TradeShift' || channel.value === 'Basware' || channel.value === 'Arco') {
						this.identifierNumberType = [
							{label: 'GLN', value: "GLN"},
							{label: 'KBO', value: "KBO"},
							{label: 'KVK', value: "KVK"}
						]

						if (channel.value === 'OpenPeppol' || channel.value === 'Peppol' || channel.value === 'Basware') {
							this.identifierNumberType.unshift({label: 'DUNS', value: "DUNS"},)
							this.identifierNumberType.unshift({label: 'BE 9925', value: "BE_VAT"},)
						}
						if (channel.value === "Peppol" || channel.value === "OpenPeppol") {
							this.identifierNumberType.unshift({label: 'GENERAL', value: "GENERAL"})
						}
					}
					if (channel.value === 'Eks') {
						this.identifierNumberType = [{label: 'GLN', value: "GLN"}]
					}
					if (channel.value === 'Ariba') {
						this.identifierNumberType = [{label: 'AN', value: "AN"}]
					}
					if (channel.value === 'Tungsten') {
						this.identifierNumberType = [{label: 'AAA', value: "AAA"}]
					}
					if (channel.value === 'ChorusPro') {
						this.identifierNumberType = [{label: 'SIRET', value: "SIRET"}]
					}
					isEdi = true
				}
			})
			return isEdi
		},
		receiverDataStore() {
			return this.$store.state.receiverManagement.receiverData
		},
	},
	watch: {
		receiverDataStore(newVal) {
			if (newVal.channelInfo && newVal.channelInfo.senderEmail) {
				this.senderEmail = newVal.channelInfo.senderEmail
			}
			if (Object.keys(newVal).length !== 0) {
				this.receiverData = JSON.parse(JSON.stringify(newVal))
				this.formData = {
					supplierGroupId: this.receiverData.supplierGroupId,
					receiverId: this.receiverData.receiverId,
					legalEntityId: this.receiverData.legalEntityId,
					holdRelease: this.receiverData.channelInfo?.holdRelease ? this.receiverData.channelInfo.holdRelease : false,
					ediInfo: {}
				}
				if (this.receiverData.channelInfo && this.receiverData.channelInfo.channelChoice) {
					this.setChannelOptionsList(this.receiverData.channelInfo.channelChoice)
					if (this.receiverData.channelInfo.channelChoice.includes("LINK_EMAIL") || this.receiverData.channelInfo.channelChoice.includes("ATTACH_EMAIL")) {
						this.displayReceiverEmails = true
					}
					let choices = []
					if (this.receiverData.channelInfo && this.receiverData.channelInfo.ediInfo) {
						this.formData.ediInfo = this.receiverData.channelInfo.ediInfo
						if (this.formData.ediInfo.companyIdentifierNumberType) {
							this.companyIdentifierNumberType = {
								value: this.receiverData.channelInfo.ediInfo.companyIdentifierNumberType,
								label: this.receiverData.channelInfo.ediInfo.companyIdentifierNumberType
							}
							if (this.receiverData.channelInfo.ediInfo.companyIdentifierNumberType === 'BE_VAT') {
								this.companyIdentifierNumberType.label = "BE 9925"
							}
						} else {
							this.companyIdentifierNumberType = {
								value: '',
								label: ''
							}
						}
						if (this.receiverData.channelInfo.ediInfo.companyIdentifierNumber) {
							this.companyIdentifierNumber = this.receiverData.channelInfo.ediInfo.companyIdentifierNumber
						} else {
							this.companyIdentifierNumber = ''
						}
					} else {
						this.formData.ediInfo = {
							companyIdentifierNumber: '',
							companyIdentifierNumberType: '',
							channelProvider: ''
						}
					}
					this.receiverData.channelInfo.channelChoice.forEach(channel => {
						if (channel === "PRINT" || channel === "LINK_EMAIL" || channel === "ATTACH_EMAIL") {
							let labelField = 'management.channelChoice.' + channel.replace('_', '').toLowerCase()
							choices.push(
								{label: i18n.t(labelField), value: channel}
							)
						} else {
							choices.push(
								{
									label: "EDI: " + this.receiverData.channelInfo.ediInfo.channelProvider,
									value: this.receiverData.channelInfo.ediInfo.channelProvider
								}
							)
						}
					})
					this.formData.channelChoice = this.receiverData.channelInfo.channelChoice
					this.selectedChannels = choices
					if (this.receiverData.channelInfo && this.receiverData.channelInfo.emails) {
						this.formData.emails = this.receiverData.channelInfo.emails
					} else {
						this.formData.emails = {}
					}
				} else {
					this.channelOptions = JSON.parse(JSON.stringify(this.channelOptionsAvailable))
					this.formData.channelChoice = []
					this.selectedChannels = []
					this.displayReceiverEmails = false
					this.formData.ediInfo = {}
				}
				this.displayComponent = true
			}
		}
	},
	methods: {
		setChannelOptions() {
			let selectedChannels = []
			this.selectedChannels.forEach(channel => {
				selectedChannels.push(channel.value)
			})

			this.setChannelOptionsList(selectedChannels)
			this.setDisplayReceiverEmails(selectedChannels)
		},
		deselectOptions() {
			this.setChannelOptions()
			this.senderEmail = ''
			this.formData.emails = {}
		},
		setChannelOptionsList(selectedChannels) {
			this.channelOptions = JSON.parse(JSON.stringify(this.channelOptionsAvailable))
			let removeIndexes = []

			if (selectedChannels.includes("ATTACH_EMAIL") || selectedChannels.includes("LINK_EMAIL")) {
				this.channelOptions.forEach((option, index) => {
					if (option.value === "ATTACH_EMAIL") {
						removeIndexes.push(index)
					}
					if (option.value === "LINK_EMAIL") {
						removeIndexes.push(index)
					}
				})
			}
			if (selectedChannels.includes("PRINT")) {
				this.channelOptions.forEach((option, index) => {
					if (option.value === "PRINT") {
						removeIndexes.push(index)
					}
				})
			}
			selectedChannels.forEach(channel => {
				let isEdiChannel = false
				let ediChannel = ''
				selectedChannels.forEach(channel => {
					if (this.ediChannels.includes(channel)) {
						isEdiChannel = true
						ediChannel = channel
					}
				})

				if (channel === "EDI" || isEdiChannel) {
					this.ediChannels.forEach((availableEdiChannel) => {
						this.channelOptions.forEach((option, index) => {
							if (option.value === availableEdiChannel) {
								removeIndexes.push(index)
							}
						})
					})
					if (isEdiChannel) {
						let identifierNumberTypeExistInList = false
						this.identifierNumberTypeMatch.forEach(item => {
							if (item.names.includes(ediChannel)) {
								identifierNumberTypeExistInList = item.identifierNumberTypes.includes(this.companyIdentifierNumberType.value)
							}
						})
						if (!identifierNumberTypeExistInList) {
							this.companyIdentifierNumberType = {value: '', label: ''}
						}
					}
				}
			})
			removeIndexes.sort((a, b) => {
				return a - b
			})
			let uniqueRemoveUIndexes = [...new Set(removeIndexes)].reverse()
			uniqueRemoveUIndexes.forEach(index => {
				this.channelOptions.splice(index, 1)
			})
		},
		setDisplayReceiverEmails(selectedChannels) {
			this.displayReceiverEmails = !!(selectedChannels.includes("LINK_EMAIL") || selectedChannels.includes("ATTACH_EMAIL"))
		},
		updateHoldRelease(data) {
			this.formData.holdRelease = data
		},
		removeContact(index) {
			if (!this.$store.state.receiverManagement.viewMode) {
				let contact = this.existingContacts[index]
				this.formData.emails[contact.language].forEach((email, index) => {
					if (contact.email === email) {
						this.formData.emails[contact.language].splice(index, 1)
					}
				})
				this.removedOldContact = true
			}
		},
		setErrors(field, value) {
			this.errors[field] = value
		},
		closeEditReceiver() {
			this.errors = {
				companyIdentifierNumber: false,
				companyIdentifierNumberType: false,
				atLeastOneEmail: false,
				emails: false,
				senderEmail: false,
				givenMailInput: []
			}
			this.formData = {
				supplierGroupId: '',
				receiverId: '',
				legalEntityId: '',
				channelChoice: [],
				ediInfo: {},
				holdRelease: false
			}
			this.selectedChannels = []
			this.companyIdentifierNumberType = ''
			this.companyIdentifierNumber = ''
			this.newReceiverEmails = []
			this.displayReceiverEmails = false

			this.channelOptions = JSON.parse(JSON.stringify(this.channels))
			this.$store.commit('displayEditReceiver', false)
			this.$store.commit('receiverDataUpdated', false)
			this.$store.commit('setReceiverData', {})
			this.senderEmail = ''
		},
		openConfirmDialog() {
			if (this.formData.holdRelease === true || this.selectedChannels.length >= 1) {
				this.showConfirmDialog = false
				return
			}
			if (this.showReleaseToggle === false && this.showOverrideChannel === false) {
				this.showConfirmDialog = false
				return
			}
			if (this.showReleaseToggle && this.formData.holdRelease === false) this.showConfirmDialog = true
			else if (this.showOverrideChannel && this.selectedChannels.length <= 0) this.showConfirmDialog = true
			else if (this.formData.holdRelease === false && this.selectedChannels.length <= 0) {
				this.showConfirmDialog = true
			}
		},
		confirmAddReceiver() {
			this.confirm.alreadyConfirmed = true
			this.showConfirmDialog = false
			this.updateReceiver()
		},
		updateReceiver() {
			if (this.formData.ediInfo === undefined) {
				this.formData.ediInfo = {}
			}
			let selectedChannelChoices = []
			if (this.confirm.alreadyConfirmed === false) {
				this.openConfirmDialog()
				if (this.showConfirmDialog) {
					return
				}
			}
			this.confirm.alreadyConfirmed = false
			this.selectedChannels.forEach(channel => {
				selectedChannelChoices.push(channel.value)
			})
			this.formData.channelChoice = selectedChannelChoices

			let isEdi = false
			let ediIndex = -1
			let channelProvider = ''
			this.formData.channelChoice.forEach((channel, index) => {
				if (this.ediChannelsAvailable.includes(channel)) {
					isEdi = true
					ediIndex = index
					channelProvider = channel
				}
			})
			if (isEdi) {
				if (this.companyIdentifierNumberType.value !== undefined) {
					this.formData.ediInfo.companyIdentifierNumberType = this.companyIdentifierNumberType.value
				} else {
					this.formData.ediInfo.companyIdentifierNumberType = this.companyIdentifierNumberType
				}
				this.formData.ediInfo.companyIdentifierNumber = this.companyIdentifierNumber
				if (this.formData.ediInfo.companyIdentifierNumberType === '') {
					this.setErrors('companyIdentifierNumberType', true)
					return
				}
				if (this.formData.ediInfo.companyIdentifierNumber === '') {
					this.setErrors('companyIdentifierNumber', true)
					return
				}

				if (this.companyIdentifierNumberType.value === "GENERAL"){
					let peppolIdentifier = this.companyIdentifierNumber.split(':')
					if (peppolIdentifier.length !== 2) {
						this.setErrors("companyIdentifierNumberWrongFormat", true)
						return
					}
					if (peppolIdentifier[0]?.length !== 4){
						this.setErrors("companyIdentifierNumberWrongFormat", true)
						return
					}
					if (!/^\d+$/.test(peppolIdentifier[0])){
						this.setErrors("companyIdentifierNumberWrongFormat", true)
						return
					}
				}
				
				this.formData.ediInfo.channelProvider = channelProvider
				this.formData.channelChoice[ediIndex] = "EDI"
			} else {
				delete this.formData.ediInfo
			}
			if (this.formData.channelChoice.includes("LINK_EMAIL") || this.formData.channelChoice.includes("ATTACH_EMAIL")) {
				if (this.senderEmail !== '') {
					if (!this.re.test(this.senderEmail)) {
						this.setErrors('senderEmail', true)
						return
					} else {
						this.formData.senderEmail = this.senderEmail
					}
				}

				if (!this.formData.emails) this.formData.emails = {}

				let emailsInvalid = false
				this.$refs.newEmails.newContacts.forEach((_, index) => this.errors.givenMailInput[index] = false)
				this.$refs.newEmails.newContacts.forEach((receiver, index) => {
					let validEmail = this.re.test(receiver.email)
					if (receiver.email === '' || receiver.language === '' || !validEmail) {
						this.errors.givenMailInput[index] = true
						emailsInvalid = true
					}
				})
				if (this.existingContacts.length > 0 && this.$refs.newEmails.newContacts.length === 1
					&& this.$refs.newEmails.newContacts[0].email === "" && this.$refs.newEmails.newContacts[0].language === "") {
					emailsInvalid = false
				}
				if (this.removedOldContact === true && this.$refs.newEmails.newContacts.length === 1) {
					if (this.$refs.newEmails.newContacts[0].email === '' && this.$refs.newEmails.newContacts[0].language === '') {
						emailsInvalid = false
					}
				}

				if (emailsInvalid) return
				this.$refs.newEmails.newContacts.forEach(receiver => {
					if (this.formData.emails[receiver.language]) {
						this.formData.emails[receiver.language].push(receiver.email)
					} else {
						if (receiver.language !== '') {
							this.formData.emails[receiver.language] = []
							this.formData.emails[receiver.language].push(receiver.email)
						}
					}
				})

				if (Object.keys(this.formData.emails).length === 0) return
				else {
					for (let lang in this.formData.emails) {
						if (!this.formData.emails[lang].length) {
							delete this.formData.emails[lang]
						}
					}
				}
			} else {
				if (this.formData.emails) delete this.formData.emails
			}
			if (this.formData.emails && Object.keys(this.formData.emails).length === 0) {
				this.setErrors('atLeastOneEmail', true)
				return
			}

			this.$store.dispatch('users/updateReceiver', {
				formData: this.formData
			})
			this.closeEditReceiver()
		}
	}
}
</script>

<style scoped>
i {
	color: var(--infoColor);
	font-size: 14px;
}

.one-item {
	display: grid;
	grid-template-columns: 40% 60%;
	grid-template-rows: 100%;
	align-items: center;
	margin-bottom: 20px;
}

h1 {
	font-weight: bolder;
	font-size: 14px;
	line-height: 1.5em;
}

.sender-emails-buttons button {
	padding-top: 0;
	padding-bottom: 0;
}

.sender-emails-container > div:nth-child(odd) {
	margin-right: 20px
}

.sender-emails-container > div:nth-child(even) {
	margin-left: 4px
}
</style>
