<template>
	<pop-up-window
		:show="this.$store.state.userManagement.displayFindUser"
		:showClose=false
		:width70=true
		:top20=true
		@close="closeFindUser"
		class="doc-statuses-popup"
		additional-info=""
		:positionAbsolute=true
		fixed
	>
		<template #title>
			<h1 class="text-2xl">{{ $t('management.findUser.title') }}</h1>
		</template>
		<template #content>
			<div class="user-info divider">
				<div class="select-div">
					<div class="le-dropdown">
						<h1 class="base-text header-text">{{ $t('general.legalEntities') }}</h1>
						<v-select
							v-model="selectedLE"
							:options='options'
							id="findUser"
							:clearable=false
						>
							<template #no-options="{search}">
								{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
							</template>
						</v-select>
					</div>
					<div class="receivers-dropdown">
						<h2 class="base-text header-text">{{ findReceiver }}</h2>
						<v-select
							@search="fetchOptions"
							v-model="selectedReceiver"
							:options=this.$store.state.receivers.options
						>
							<template #no-options="{search}">
								{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
							</template>
						</v-select>
					</div>
				</div>
				<div>
					<find-user-table
						v-if=displayReceiversTable
						:supplierGroupId="this.supplierGroupId"
						:selectedLE="selectedLE.value"
						:selectedReceivers="receiversToSend"
						:token="this.$store.state.token"
						:requestUrl="this.requestUrl">
					</find-user-table>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-center my-6">
				<div>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="closeFindUser">
						{{ $t('buttons.close') }}
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow.vue"
import FindUserTable from "./FindUserTable.vue"
import userGroups from "../../../userGoups"
import i18n from "../../../i18n"

export default {
	name: "FindUser",
	components: {FindUserTable, PopUpWindow},
	mounted() {
		this.fetchOptions('')
	},
	data() {
		return {
			i18n: i18n,
			displayTable: false,
			selectedLE: {},
			selectedReceiver: {},
			selectedReceiversArray: [],
			selectedReceiverIds: [],
			receiversToSend: [],
			supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId,
			requestUrl: this.$store.state.domainName + this.$store.state.API.getFor,
		}
	},
	watch: {
		selectedReceiver(newVal) {
			if (newVal !== null) {
				if (newVal.value !== undefined) {
					this.selectedLE.value = newVal.value.legalEntityId
					this.receiversToSend = [newVal.value.receiverId];
				}
			}
		},
		selectedLE() {
			this.displayTable = true
			this.selectedReceiver = {}
			this.selectedReceiversArray = []
			this.selectedReceiverIds = []
			this.receiversToSend = []
			this.fetchOptions()
		}
	},
	computed: {
		findReceiver() {
			if (this.$store.state.supplierGroup.data.sgType === "SALARY") {
				return i18n.t('general.findEmployee')
			}
			return i18n.t('general.findReceiver')
		},
		displayReceiversTable() {
			return !((this.selectedLE && Object.keys(this.selectedLE).length === 0 && Object.getPrototypeOf(this.selectedLE) === Object.prototype)
				|| (this.receiversToSend.length === 0));
		},
		options() {
			let options = []
			let legalEntities = this.$store.state.legalEntitiesData.data.legalEntities
			let label = ''
			for (let le in legalEntities) {
				if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
					|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
					label = legalEntities[le].internalName ? legalEntities[le].internalName : legalEntities[le].name
				} else {
					label = legalEntities[le].name
				}
				let tempItem = {
					label: label,
					value: legalEntities[le].entityNumber,
				}
				options.push(tempItem)
			}
			return options
		}
	},
	methods: {
		fetchOptions(search) {
			let selectedLE = this.selectedLE.value
			if (selectedLE === undefined) {
				let LE = []
				let legalEntities = this.$store.state.legalEntitiesData.data.legalEntities
				for (let le in legalEntities) {
					LE.push(legalEntities[le].entityNumber)
				}
				selectedLE = LE
			} else if (Array.isArray(this.selectedLE.value)) {
				selectedLE = this.selectedLE.value
			} else {
				selectedLE = [this.selectedLE.value]
			}

			this.$store.dispatch('users/fetchReceivers', {
				token: this.$root.token,
				formData: {
					supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId,
					legalEntityIds: selectedLE,
					quick: search
				}
			})
			if (this.selectedLE.value === undefined) {
				this.selectedLE.value = selectedLE
			}
		},
		closeFindUser() {
			this.$store.commit('displayFindUser', false)
			this.selectedLE = {}
			this.selectedReceiver = {}
			this.selectedReceiversArray = []
			this.selectedReceiverIds = []
			this.receiversToSend = []
		}
	}
}
</script>

<style scoped>
.divider {
	padding-bottom: calc(0.5 * var(--generalPaddings));
	border-bottom: 1px solid rgba(226, 232, 240, var(--tw-border-opacity));
}

.select-div {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 50% 50%;
	grid-auto-flow: row;
}

.le-dropdown {
	margin-right: var(--generalPaddings);
}

@media only screen and (max-width: 600px) {
	.select-div {
		display: grid;
		grid-template-rows: 50% 50%;
		grid-template-columns: 100%;
		grid-auto-flow: row;
	}

	.le-dropdown[data-v-6fcdefb0] {
		margin-right: 0;
	}
}
</style>