<template>
    <div>
        <div v-if="show" class="backdrop"></div>
        <transition name='dialog'>
            <dialog open v-if="show"
                    :class="{'absolute' : positionAbsolute,
                              'fixed' : positionFixed,
                              'width60' : width60,
                              'width70' : width70,
                              'width80' : width80,
                              'top0' : top0,
                              'top10' : top10,
                              'top20' : top20,
                              'top25' : top25,
                              'zIndexTop' : zIndexTop
                              }"
                    :style = "{'top' : setScrollPosition}"
            >
                <header :style="{'background-color': brandSettings.mainColor}">
                        <div v-if="showClose" @click="close" class="close">X</div>
                        <div class="header">
                            <slot name="title"></slot>
                            <slot name="additionalInfo"></slot>
                        </div>
                </header>
                <section class="content-styles">
                    <slot name="content"></slot>
                </section>
                <div :class="{'add-bottom-div' : bottomDiv}"></div>
            </dialog>
        </transition>
    </div>
</template>
<script>
export default {
    name: "PopUpWindow",
    props: {
        zIndexTop : {
            type: Boolean,
            required: false,
            default: false,
        },
        bottomDiv:{
            type: Boolean,
            required: false,
            default: true,
        },
        width60: {
            type: Boolean,
            required: false,
            default: false,
        },
        width70: {
            type: Boolean,
            required: false,
            default: false,
        },
        width80: {
            type: Boolean,
            required: false,
            default: false,
        },
        top0: {
            type: Boolean,
            required: false,
            default: false,
        },
        top10: {
            type: Boolean,
            required: false,
            default: false,
        },
        top20: {
            type: Boolean,
            required: false,
            default: false,
        },
        top25: {
            type: Boolean,
            required: false,
            default: false,
        },
        positionFixed: {
            type: Boolean,
            required: false,
            default: false
        },
        positionAbsolute: {
            type: Boolean,
            required: false,
            default: true
        },

        showClose: {
            type: Boolean,
            required: false,
            default: false
        },
        show: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
        additionalInfo: {
            type: String,
            required: false,
        },
        button1: {
            type: String,
            required: false
        },
        button2: {
            type: String,
            required: false
        },
        stickToScrollPosition : {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        setScrollPosition(){
            if (this.stickToScrollPosition === true){
                return this.$store.state.pageYScrollPosition + 'px'
            }
            let top = '20hv';
            if (this.top0 === true){
                top = '0hv'
            }
            if (this.top10 === true){
                top = '10hv'
            }
            if (this.top20 === true){
                top = '20hv'
            }
            if (this.top25 === true){
                top = '25hv'
            }

            return top
        },
        brandSettings() {
            return this.$store.state.supplierGroup.branding
        },
    },
    methods: {
        close(){
            this.$emit('close')
        }
    }
}
</script>
<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 100;
}
.absolute{
    position: absolute;
}
.fixed{
    position: fixed;
    top: 25vh;
}
.top25{
    top: 25vh;
}

dialog {
    top: 35vh;
    left: 10%;
    width: 80%;
    z-index: 200;
    border-radius: 0.375rem;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: transparent;
}

.top20{
    top: 20vh;
}

.top10{
    top: 10vh;
}

.top0{
    top: 0vh;
}

section{
    background-color: white;
    box-shadow: 0px 3px 20px #0000000b;
	padding: var(--generalPaddings);
	border: 1px solid #d9d9d9;
}

header {
    color: white;
    width: 100%;
    padding: 1rem;
}

header h2 {
    margin: 0;
}

.header {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-auto-flow: column;
    justify-items: center;
}

.dialog-enter-active {
    transition: all 0.3s ease-out;
}

.dialog-leave-active {
    transition: all 0.3s ease-in;
}

.dialog-enter-from, .dialog-leave-to {
    transform: scale(0.8);
    opacity: 0;
}

.close{
    cursor: pointer;
    font-weight: bold;
    float: right;
}

.width70{
     left: calc(50% - 35vw) !important;
     width: 70vw !important;
 }

.width80{
    left: calc(50% - 40vw) !important;
    width: 80vw !important;
}

@media (max-width: 600px) {
    .width70{
        left: calc(50% - 45vw) !important;
        width: 90vw !important;
    }
}
@media (min-width: 1000px) {
    dialog {
        left: calc(50% - 20rem);
        width: 40rem;
    }
    .width60{
        left: calc(50% - 30rem) !important;
        width: 60rem !important;
    }
}

.content-styles, .header{
    font-size: 1.125rem;
    line-height: 1.75rem;
}

@media (max-width: 520px) {
    .content-styles, .header {
        font-size: 1rem;
        line-height: 1.4rem;
    }
}
@media (max-width: 500px) {
    .content-styles{
        padding: var(--generalPaddings) 10px;
    }
}

@media (max-width: 450px) {
    dialog {
        left: 5%;
        width: 90%;
    }
}
.add-bottom-div{
    height: 200px;
    background-color: transparent;
}
.zIndexTop{
    z-index: 600;
}
</style>