<template>
	<div class="report-container">
		<error-notification/>
		<p class="error" v-if="errors.reportType">{{ $t('errors.reportType') }}</p>
		<div class="le-container" v-if="displayLe">
			<h1>{{ $t('reports.choseLE') }}</h1>
			<v-select
				v-model="selectedLE"
				@click='setError("leError", false)'
				:options='options'
				multiple=true
				class="le-dropdown"
			>
				<template #no-options="{search}">
					{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
				</template>
			</v-select>
			<p class="error" v-if="errors.leError">{{ $t('errors.choseLegalEntity') }}</p>
		</div>
		<div class="date-container">
			<h1>{{ $t('reports.choseDate.part1') }} {{ maxReportDays }} {{ $t('reports.choseDate.part2') }}</h1>
			<div class="dates">
				<div class="selected-date-range">
					<h2 class="font-bold">{{ $t('general.from') }}</h2>
					<div class="date-picker-container">
						<vue-date-picker
							v-model="from"
							auto-apply
							class="calendar-itself"
							:enableTimePicker=false
							:format="format"
							@click="setError('dateError', false)"
						>
							<template #input-icon>
								<span class="material-icons calendar-icon">calendar_month</span>
							</template>
							<template #clear-icon="{clear}">
								<span class="material-icons clear-icon" @click="clear">close</span>
							</template>
						</vue-date-picker>
					</div>
				</div>
				<div class="selected-date-range">
					<h2 class="font-bold">{{ $t('general.to') }}</h2>
					<div class="date-picker-container">
						<vue-date-picker
							v-model="to"
							auto-apply
							class="calendar-itself"
							:enableTimePicker=false
							:format="format"
							@click="setError('dateError', false)"
						>
							<template #input-icon>
								<span class="material-icons calendar-icon">calendar_month</span>
							</template>
							<template #clear-icon="{clear}">
								<span class="material-icons clear-icon" @click="clear">close</span>
							</template>
						</vue-date-picker>
					</div>
				</div>
			</div>
			<p class="error" v-if="errors.dateError">{{ $t('errors.maxDateRange') }}</p>
		</div>
		<div>
			<h1>{{ $t('reports.reportType') }}</h1>
			<v-select
				:options="reportTypeOptions"
				v-model="reportType"
			/>
		</div>
		<div class="buttons-container">
			<button class="btn custom-button" @click="downloadReport">
				{{ $t('buttons.downloadReport') }}
			</button>
		</div>
	</div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import {computed, onMounted, ref, watch} from 'vue'
import {useStore} from "vuex"
import ErrorNotification from "../UI/ErrorNotification"
import i18n from "../../i18n"

export default {
	name: "ReportForm",
	components: {
		ErrorNotification,
		VueDatePicker
	},
	props: {
		activeReport: {
			type: String,
			required: true
		}
	},
	setup(props) {
		onMounted(() => {
			setAllLE()
		})
		const reportType = ref({label: '.csv', value: '.csv'})
		const date = ref([new Date, new Date])
		const format = (date) => {
			const day = date.getDate()
			const month = date.getMonth() + 1
			const year = date.getFullYear()
			return `${day}/${month}/${year}`
		}
		const reportTypeOptions = ref([
			{label: '.csv', value: '.csv'},
			{label: '.xlsx', value: '.xlsx'},

		])
		const activeReportData = ref(props.activeReport)
		const selectedLE = ref([])
		const allLE = ref([])
		const errors = ref({
			dateError: false,
			leError: false,
			reportType: false,
		})
		const store = useStore()

		let to = ref(new Date())
		let from = ref(new Date())

		watch(activeReportData, (newVal, oldVal) => {
			if (oldVal === '') errors.value.reportType = false
		})
		watch(() => store.state.locale, () => {
			if (selectedLE.value.length) {
				let allLeElement = selectedLE.value.find(item => item.value === "selectAll")
				allLeElement.label = i18n.t('general.allLegalEntity')
			}
			let AllLeElementInOptions = options.value.find(item => item.value === "selectAll")

			AllLeElementInOptions.label = i18n.t('general.allLegalEntity')
		})
		watch(() => props.activeReport, (newVal) => {
			activeReportData.value = newVal
		})

		let maxReportDays = computed(() => {
			if (store.state.supplierGroup.data.channelsConfig.docImage.configPortal?.maxReportDays) {
				return store.state.supplierGroup.data.channelsConfig.docImage.configPortal.maxReportDays.toString()
			}
			return "30"
		})
		let displayLe = computed(() => {
			return !(activeReportData.value === "postDropReport" || activeReportData.value === "sgSpecificErrorsReport")
		})
		let options = computed(() => {
			let options = []

			let legalEntities = store.state.legalEntitiesData.data.legalEntities
			for (let le in legalEntities) {
				let label = legalEntities[le].internalName ? legalEntities[le].internalName : legalEntities[le].name
				let tempItem = {
					label: label,
					value: legalEntities[le].entityNumber,
				}
				options.push(tempItem)
			}
			options.sort(function (a, b) {
				const textA = a.label.toUpperCase()
				const textB = b.label.toUpperCase()
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
			})
			let selectAll = {
				label: i18n.t('general.allLegalEntity'),
				value: "selectAll"
			}
			options.unshift(selectAll)
			return options
		})

		const setError = (error, value) => {
			errors.value[error] = value
		}
		const setAllLE = () => {
			let legalEntities = store.state.legalEntitiesData.data.legalEntities
			for (let le in legalEntities) {
				allLE.value.push(legalEntities[le].entityNumber)
			}
		}
		const downloadReport = () => {
			if (activeReportData.value === '') {
				errors.value.reportType = true
				return
			}
			if (from.value === null || to.value === null) {
				errors.value.dateError = true
				return
			}
			let formData = {
				from: from.value,
				to: to.value,
				legalEntities: []
			}
			let selectAll = false
			selectedLE.value.forEach(le => {
				if (le.value === "selectAll") selectAll = true
			})
			if (selectAll) {
				formData.legalEntities = allLE.value
			} else {
				selectedLE.value.forEach(le => formData.legalEntities.push(le.value))
			}

			if (!(activeReportData.value === "postDropReport" || activeReportData.value === "sgSpecificErrorsReport") && formData.legalEntities.length === 0) {
				errors.value.leError = true
				return
			}
			from.value.setUTCHours(0, 0, 0)
			to.value.setUTCHours(23, 59, 59)

			let dateRage = Math.ceil((new Date(to.value).getTime() - new Date(from.value).getTime()) / (1000 * 60 * 60 * 24))
			if (dateRage > maxReportDays.value) {
				setError('dateError', true)
				return
			}
			if (activeReportData.value === "postDropReport") {
				delete formData.legalEntities
			}

			errors.value.leError = false
			errors.value.dateError = false
			store.dispatch('report/downloadReport', {
				formData: formData,
				type: activeReportData.value,
				reportType: reportType.value.value,
			})
			store.commit('displayLoadingNotification', {display: true, type: activeReportData.value})
		}

		return {
			date,
			from,
			to,
			format,
			activeReportData,
			selectedLE,
			allLE,
			options,
			setAllLE,
			downloadReport,
			errors,
			setError,
			reportType,
			maxReportDays,
			reportTypeOptions,
			displayLe
		}
	}
}
</script>

<style scoped>
.report-container {
	width: 600px;
}

.report-container > div {
	margin-bottom: var(--generalPaddings);
}

h1 {
	font-weight: bolder;
	font-size: 14px;
	margin-bottom: calc(0.5 * var(--generalPaddings));
}

.date-picker-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
}

.calendar-itself {
	grid-column: 1/2;
	grid-row: 1/2;
}

.buttons-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: center;
	border-top: 1px solid var(--theme-primary-50);
	padding-top: var(--generalPaddings);
}

.error {
	margin-top: 5px;
}

@media only screen and (max-width: 730px) {
	.report-container {
		width: 100%;
	}
}

.selected-date-range {
	width: 100%;
}

.selected-date-range:nth-child(1) {
	padding-right: 4px;
}

.selected-date-range:nth-child(2) {
	padding-left: 4px;
}

.dates {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 100%;
}

.calendar-icon {
	padding-top:5px;
	font-size: 20px;
	padding-left: 6px;
	padding-right: 12px;
	color: #355983;
}

.clear-icon {
	position: absolute;
	font-size: 20px;
	top: 12px;
	right: 15px;
	color: #82858a;
	cursor: pointer;
}
</style>